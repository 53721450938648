/*eslint-disable */
const $ = window.jQuery = require('jquery');

import { bus } from '@/main';
import moment from 'moment';
import videoSocketRef from "@/services/videoNotificationSocket/";

var patientChatScoket;
export default {
  name: 'chat-of-patient',
  components: {},
  props: ['chateRoomIdOfPateint'],
  data() {
    return {
      selfUserId: '',
      chatWithPatientId: '',
      patientChatDetails:null,
      patientChatDetailResults: [],
      selection_type: 'press_enter',
      checkingActvTabId: '',
      patientChatRoomId: '',
      callTextState:false,
      typingUserInfoState:null,
      doctorInfo:null,
      roomId:''
    }
  },
  computed: {

  },
  mounted() {
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
      this.selfUserInfo = JSON.parse(localStorage.getItem('LoginedUserInfo'))
    }
    if(patientChatScoket && patientChatScoket.readyState) {
      patientChatScoket.close();
    }
    if (this.chateRoomIdOfPateint) {
      this.doctorInfo = this.chateRoomIdOfPateint.doctor_detail
      this.roomId = this.chateRoomIdOfPateint.chat_room_id
      this.createRoomWithPatient( this.roomId)
    }
    bus.$on('userUpdateStatus', (data) => {
      let user = this.chateRoomIdOfPateint.id == data.userID;
      if(user) {
        this.chateRoomIdOfPateint.is_online = data.is_online;
      }
    });
    bus.$on('TypingEventForSelectedUserBus', (data) => {
      this.typingUserInfoState = data
      if (data.message == 'stop') {
        this.typingUserInfoState = null;
      }
    })
  },
  methods: {
    /*
    * This Will Scroll Bottom Of ChatBox
    */
    chatScrollBottomMethod() {
      var elmnt = document.getElementById("chat_msg_list_scroll");
      var y = elmnt.scrollHeight;
      var scrollH = elmnt.clientHeight;
      var scrollTop = elmnt.scrollTop
      var FullSH = scrollH + scrollTop
      var scrollD = y - FullSH
      if (parseInt(60) > scrollD) {
        $('#chat_msg_list_scroll').animate({ scrollTop: y }, 20)
      }
    },
    newline() { },
    send() { },
    enabledYearDateFun(msgDateTime) {
      var currentLocalDate = moment().format('YYYY');
      var startDateCheck = moment(msgDateTime).format('YYYY')
      if (currentLocalDate > startDateCheck) {
        return true;
      } else {
        return false;
      }
    },
    createRoomWithPatient(createdRoomChatId) {
      const authToken = localStorage.getItem('token');
      this.$store.dispatch('getChatRoomDetailForPatientMethod', { id: createdRoomChatId }).then((response) => {
        this.patientChatDetails = response.data.data
        this.patientChatDetailResults = [].concat(response.data.data.results)
        this.chatScrollAlwaysBottomMethod()
        // Chat With patient Socket Init
        var __self = this
         const messagesList = document.getElementById('list_of_patient_chat_msg_wrp')
          patientChatScoket = new WebSocket(
            'wss://' + process.env.VUE_APP_WEBSOCKET_BASE_URL +
            '/ws/chat/' + createdRoomChatId + '/?token=' + authToken
          );
          // Action On Live Socket
          setTimeout(() => {
            var timeout;
            // Message Send Features Code Here
            document.querySelector('#chat-message-reply-input').onkeyup = function (event) {
              var messageInputDom = document.querySelector('#chat-message-reply-input');
              if (__self.selection_type == 'press_enter') {
                __self.msgValue = messageInputDom.value;
                if (event.keyCode === 13) {
                  if (event.shiftKey) {
                    event.stopPropagation();
                  } else if (!event.shiftKey) {
                    event.preventDefault();
                    document.querySelector('#chat-message-reply-submit').click();
                  }
                }else {
                  // if (messageInputDom.value.length > 0) {
                  //   videoSocketRef.emit({
                  //     type: "TypingEventForSelectedUser",
                  //     message: 'typing',
                  //     selfUserID:selfUserId,
                  //     targetForChatUserId:patientId,
                  //     second_user_id:patientId
                  //   });
                  //   clearTimeout(timeout)
                  //   timeout = setTimeout(() => {
                  //     videoSocketRef.emit({
                  //       type: "TypingEventForSelectedUser",
                  //       message: 'stop',
                  //       selfUserID:selfUserId,
                  //       targetForChatUserId: patientId,
                  //       second_user_id:patientId
                  //     })
                  //   }, 2000)
                  // }
                }
              }
            };
            document.querySelector('#chat-message-reply-input').focus();
            document.querySelector('#chat-message-reply-submit').onclick = function (e) {
              var messageInputDom = document.querySelector('#chat-message-reply-input');
              __self.msgValue = messageInputDom.value;
              var message = __self.msgValue;
              var msg = message.replace(/</g, "&lt;").replace(/>/g, "&gt;").trim();
              if (msg !== "") {
                const currentUserId = localStorage.getItem('LoginedUserID');
                // const payload = {
                //   toUserID: sentUserInfo.id,
                //   fromUserID: currentUserId,
                //   chatRoomId: createdRoomChatId,
                //   senderMsgData: message,
                //   msgSenderFullName: __self.selfUserInfo.first_name + ' ' + __self.selfUserInfo.last_name,
                //   lbilWorkspace:'yes',
                // };
                let sentData = {
                  'content': message,
                  // 'message': message,
                  // email: sentUserInfo.email,
                  // senderID: sentUserInfo.id,
                  // name: sentUserInfo.first_name + ' ' + sentUserInfo.last_name,
                  // selfUserID: __self.selfUserId,
                  // is_read: false
                }
                // videoSocketRef.emit({
                //   type: "TypingEventForSelectedUser",
                //   message: 'stop',
                //   selfUserID:selfUserId,
                //   targetForChatUserId: patientId,
                //   second_user_id:patientId
                // })
                // clearTimeout(timeout)
                patientChatScoket.send(JSON.stringify({"type": "chat_message", "message": sentData}))
                // let socketTimeout = setTimeout(function () {
                //   bus.$emit('newChatMessage', payload);
                //   clearTimeout(socketTimeout);
                // }, 2000);
                messageInputDom.value = '';
                __self.msgValue = ''
              }
            };
          }, 200)
          // Message Checking ON Live Socket
          patientChatScoket.onmessage = function (e) {
            var data = JSON.parse(e.data);
            // console.log(data, "Checking")
            var pchatmsg_recieved = document.createElement("div");
            var pchat_msg_item = document.createElement("div");
            var msg_time_wrp = document.createElement("span");
            var msg_time = document.createElement("time");
            msg_time_wrp.setAttribute("class", 'msg_time_wrp');
            msg_time.setAttribute("class", 'msg_time');
            pchat_msg_item.appendChild(document.createTextNode(data.decrypted_content));
            let dateTimeAdd = [moment(data.timestamp).format('h:mm a')];
            msg_time.appendChild(document.createTextNode(dateTimeAdd));
            msg_time_wrp.appendChild(msg_time);
            pchat_msg_item.appendChild(msg_time_wrp);
            pchatmsg_recieved.appendChild(pchat_msg_item);
            if(data.sender.user_id == __self.selfUserId){
              pchatmsg_recieved.setAttribute("class", 'append_pchatmsg pchatmsg_recieved');
              pchat_msg_item.setAttribute("class", "pchat_msg_item pchat_recieved_msg");
            }else{
              pchatmsg_recieved.setAttribute("class", 'append_pchatmsg pchatmsg_sent');
              pchat_msg_item.setAttribute("class", "pchat_msg_item pchat_send_msg");
            }
            messagesList.appendChild(pchatmsg_recieved);
            __self.chatScrollAlwaysBottomMethod()
          }
          // Socket Close Event
          patientChatScoket.onclose = function (e) {
            console.error("Socket Closed")
          }
          bus.$on('socketClosedNotifications', (data) => {
            if (patientChatScoket && patientChatScoket.readyState) {
              patientChatScoket.close();
            }
          })
      })
    },
    chatScrollAlwaysBottomMethod() {
      setTimeout(() => {
        var elmnt = document.getElementById("chat_msg_list_scroll");
        var y = elmnt.scrollHeight;
        elmnt.scrollTop = y
      }, 100)
    },
    chatClosedMethod() {
      if (patientChatScoket && patientChatScoket.readyState) {
        patientChatScoket.close();
      }
    },
    /*
    * This Will Video Call Request Function
    */
    videoCallRequestFun(patientInfo) {
      let callerId = patientInfo.id
      this.callTextState = true;
      let callerFullName = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
      videoSocketRef.emit({
        message: `${callerFullName} is calling you.`,
        type: "videoCalling",
        displayName: callerFullName,
        callerID: this.selfUserId,
        targetId: callerId,
        second_user_id:callerId,
        'chat_data': {
          'message': 'Missed Call'
        }
      });
      videoSocketRef.$on('videoCallAcceptedNotification', this.getAnswerOfVideoCall);
      videoSocketRef.$on('videoCallDeclinedNotification', this.getDeclinedOfVideoCall);
      videoSocketRef.$on('videoCallAlertPopupClosed', this.notrecievedCall);
      videoSocketRef.$on('autoMissedCallEvent', this.autoMissedCall);
    },
    getAnswerOfVideoCall(getAnswerData) {
      if (this.selfUserId == getAnswerData.returnCallerUserId) {
        this.$router.push('/video/conference/#' + getAnswerData.videoCallRoomId)
      }
    },
    getDeclinedOfVideoCall(declinedData) {
      this.callTextState = false
    },
    notrecievedCall(notrecievedData) {
      this.callTextState = false
    },
    autoMissedCall(mCallData) {
      this.callTextState = false
    },
  },
  watch: {
    chateRoomIdOfPateint(newVal, oldVal) {
      this.chatWithPatientId = newVal.id
    }
  },
}