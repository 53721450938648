/*eslint-disable */

import {bus} from '@/main';
import { mapGetters } from 'vuex';
import pdfViewer from './pdf-viewer';
import updateProfileInfo from '@/components/private/update-profile-info-popup/index.vue';

export default {
  name: 'pre-fo-doctor',
  components: {
    'pdf-viewer' : pdfViewer,
    'update-profile-popup' : updateProfileInfo
  },
  props: ['doctorId'],
  data () {
    return {
      prescriptionListData:null,
      prescriptionListResults:[],
      pdfViewerPopup:false,
      pdfViewerFileName:'',
      pdfViewerUrl:'',
      selfUserDetailData:null,
      updatedProfilePopup:false
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters'
    ]),
    getSelfUserInfoMethod() {
      this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data;
      return;
    },
  },
  mounted () {
    this.getPrescriptionListData();
    bus.$on('pdfViewerPopupBus', (data) => {
      this.pdfViewerPopup = data;
    });
    bus.$on('patientPrescriptionBus', (data) => {
      this.getPrescriptionListData()
    });
    bus.$on('updatedProfilePopupBus', (data) => {
      this.updatedProfilePopup = data
    });
  },
  methods: {
    updatedProfilePopupMethod(){
      document.body.classList.add('popup_open_body')
      this.updatedProfilePopup = true
    },
    getPrescriptionListData(){
      this.$store.dispatch('getPrescriptionListApiFun', {id:this.doctorId}).then((res)=>{
        this.prescriptionListData = res.data.data
        this.prescriptionListResults =  this.prescriptionListData.results
      })
    },
    pdfViewerFun(url){
      var getName = url.split("?")[0].split("/")
      const fileName = getName[getName.length - 1]
      this.pdfViewerFileName = fileName
      this.pdfViewerUrl = url
      this.pdfViewerPopup = true
      document.body.classList.add('pdf_view_actv')
    },
  },
  watch:{
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters;
    },
  }
}